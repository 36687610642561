import { useApi } from '~/composables/api/useApi'

export function useSubscriptions() {
  const { fetchWithAuth } = useApi()
  const config = useRuntimeConfig()
  const subscriptionApi =
    config.public.subscriptionsApi || 'https://api-subscription.transkribus.eu'

  // GET Requests

  async function checkTrainingRuns(providedOptions: { userId: number }) {
    return { trainingRuns: 5 }
  }

  async function getSubscription(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${subscriptionApi}/subscriptions/${providedOptions.userId}`,
      'GET',
      {}
    )
  }

  async function getSitesSubscription(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${subscriptionApi}/sites/${providedOptions.userId}`,
      'GET',
      {}
    )
  }

  async function getAllOrganisations() {
    return await fetchWithAuth(`${subscriptionApi}/organizations`, 'GET', {})
  }

  async function getOrganisationUsers(providedOptions: { id: number }) {
    return await fetchWithAuth(
      `${subscriptionApi}/organizations/${providedOptions.id}/users`,
      'GET',
      {}
    )
  }

  async function getOrganisation(providedOptions: { id: number }) {
    return await fetchWithAuth(
      `${subscriptionApi}/organizations/${providedOptions.id}`,
      'GET',
      {}
    )
  }

  // POST Requests

  async function setSitesSubscriptionUrl(providedOptions: {
    subscriptionId: number
    url: URL
  }) {
    return await fetchWithAuth(
      `${subscriptionApi}/sites/${providedOptions.subscriptionId}/url`,
      'POST',
      {
        data: { url: providedOptions.url },
      }
    )
  }

  async function setSubscription(providedOptions: {
    subscriptionId: number
    userId: number
    planId: number
    expirationDate: string
    organizationName: string
  }) {

    return await fetchWithAuth(
      `${subscriptionApi}/ecom-subscriptions`,
      'POST',
      {
        data: {
          subscriptionId: providedOptions.subscriptionId,
          userId: providedOptions.userId,
          planId: providedOptions.planId,
          expirationDate: providedOptions.expirationDate,
          organizationName: providedOptions.organizationName,
        },
      }
    )
  }

  async function addUserToOrganisation(providedOptions: {
    id: number
    newUserId: number
  }) {
    return await fetchWithAuth(
      `${subscriptionApi}/organizations/${providedOptions.id}/users`,
      'POST',
      {
        data: { userId: providedOptions.newUserId },
      }
    )
  }

  // PUT Requests

  async function updateOrganisation(options: {
    id: number
    name?: string
    description?: string
    limit?: number
    managerIds?: Array<number>
  }) {
    return await fetchWithAuth(
      `${subscriptionApi}/organizations/${options.id}`,
      'PUT',
      {
        data: {
          name: options.name,
          description: options.description,
          managerIds: options.managerIds,
          limit: options.limit,
        },
      }
    )
  }

  async function startTrial(options: { userId: number }) {
    return await fetchWithAuth(
      `${subscriptionApi}/subscriptions/trial/${options.userId}`,
      'PUT'
    )
  }

  // DELETE Requests

  async function removeUserFromOrganisation(options: {
    id: number
    userId: number
  }) {
    return await fetchWithAuth(
      `${subscriptionApi}/organizations/${options.id}/users/${options.userId}`,
      'DELETE'
    )
  }

  async function deleteOrganisation(options: { id: number }) {
    return await fetchWithAuth(
      `${subscriptionApi}/organizations/${options.id}`,
      'DELETE'
    )
  }

  return {
    getSubscription,
    getSitesSubscription,
    setSitesSubscriptionUrl,
    setSubscription,
    getAllOrganisations,
    getOrganisationUsers,
    getOrganisation,
    updateOrganisation,
    addUserToOrganisation,
    removeUserFromOrganisation,
    startTrial,
    checkTrainingRuns,
    deleteOrganisation
  }
}
